const _temp0 = require("./components/accordion/_accordion.scss");

const _temp1 = require("./components/articledetail/_articledetail.scss");

const _temp2 = require("./components/button/_button.scss");

const _temp3 = require("./components/carousel/_carousel.scss");

const _temp4 = require("./components/container-isi/_container-isi.scss");

const _temp5 = require("./components/custom-select/_custom-select.scss");

const _temp6 = require("./components/embed/_embed.scss");

const _temp7 = require("./components/flippable-card/_flippable-card.scss");

const _temp8 = require("./components/footer/_footer.scss");

const _temp9 = require("./components/form/_form.scss");

const _temp10 = require("./components/header/_header-light.scss");
const _temp11 = require("./components/header/_header-sticky.scss");
const _temp12 = require("./components/header/_header.scss");
const _temp13 = require("./components/header/_nav.scss");

const _temp14 = require("./components/image-map/_image-map.scss");

const _temp15 = require("./components/injectionspoint/_injectionspoint.scss");

const _temp16 = require("./components/modal/_modal.scss");

const _temp17 = require("./components/news/_news.scss");

const _temp18 = require("./components/share/_share.scss");

const _temp19 = require("./components/swiper/_swiper.scss");

const _temp20 = require("./components/tabs/_tabs.scss");

const _temp21 = require("./components/teaser/_teaser.scss");

const _temp22 = require("./components/text/_text.scss");

module.exports = {
  "accordion":   {
    "_accordion": _temp0
  },
  "articledetail":   {
    "_articledetail": _temp1
  },
  "button":   {
    "_button": _temp2
  },
  "carousel":   {
    "_carousel": _temp3
  },
  "container-isi":   {
    "_container-isi": _temp4
  },
  "custom-select":   {
    "_custom-select": _temp5
  },
  "embed":   {
    "_embed": _temp6
  },
  "flippable-card":   {
    "_flippable-card": _temp7
  },
  "footer":   {
    "_footer": _temp8
  },
  "form":   {
    "_form": _temp9
  },
  "header":   {
    "_header-light": _temp10,
    "_header-sticky": _temp11,
    "_header": _temp12,
    "_nav": _temp13
  },
  "image-map":   {
    "_image-map": _temp14
  },
  "injectionspoint":   {
    "_injectionspoint": _temp15
  },
  "modal":   {
    "_modal": _temp16
  },
  "news":   {
    "_news": _temp17
  },
  "share":   {
    "_share": _temp18
  },
  "swiper":   {
    "_swiper": _temp19
  },
  "tabs":   {
    "_tabs": _temp20
  },
  "teaser":   {
    "_teaser": _temp21
  },
  "text":   {
    "_text": _temp22
  }
}